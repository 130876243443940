import React, { useEffect, useState } from 'react';
import axios from 'axios';
import AnaliseFullByAdmin from './AnaliseFullByAdmin';
import PromptSection from '../admin/PromptSection';
import './AdminPanel.css'; 

const AdminPanel = () => {
  const [analyses, setAnalyses] = useState([]);
  const [filteredAnalyses, setFilteredAnalyses] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedAnalysis, setSelectedAnalysis] = useState(null);
  const [sampleType, setSampleType] = useState(''); // Filtro de tipo de amostra
  const [searchTerm, setSearchTerm] = useState(''); // Filtro de palavra-chave
  const [statusFilter, setStatusFilter] = useState(''); // Filtro de status
  const [dateFilter, setDateFilter] = useState(''); // Filtro de data

  useEffect(() => {
    const fetchAnalyses = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/analises`);
        setAnalyses(response.data);
        setFilteredAnalyses(response.data); // Inicializa as análises filtradas
      } catch (error) {
        console.error('Erro ao buscar análises:', error);
      }
    };

    fetchAnalyses();
  }, []);

  const handleOpenAnalysis = (analise) => {
    setSelectedAnalysis(analise);
    setShowModal(true);
  };

  const handleCloseModal = (e) => {
    if (e.target.className === 'modal-overlay' || e.target.className === 'close-modal-btn') {
      setShowModal(false);
      setSelectedAnalysis(null);
      handleClearFilters(); // Limpa os filtros ao fechar o modal
    }
  };

  const handleClearFilters = () => {
    setSampleType('');
    setSearchTerm('');
    setStatusFilter('');
    setDateFilter('');
    setFilteredAnalyses(analyses); // Reseta as análises filtradas para todas
  };

  // Função para aplicar os filtros de tipo de amostra, palavra-chave, status e data
  useEffect(() => {
    const filterAnalyses = () => {
      let filtered = analyses;

      if (sampleType) {
        filtered = filtered.filter((analysis) => 
          analysis.analysisData[0].sampleType.toLowerCase() === sampleType.toLowerCase()
        );
      }

      if (statusFilter) {
        filtered = filtered.filter((analysis) => 
          analysis.analysisData[0].status.toLowerCase() === statusFilter.toLowerCase()
        );
      }

      if (searchTerm) {
        filtered = filtered.filter((analysis) => 
          analysis.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
          analysis.analysisData[0].responsibleName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          analysis.analysisData[0].sampleIdentification.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }

      if (dateFilter) {
        filtered = filtered.filter(
          (analise) =>
            new Date(analise.analysisData[0].collectionDate).toLocaleDateString() ===
            new Date(dateFilter).toLocaleDateString()
        );
      }

      setFilteredAnalyses(filtered);
    };

    filterAnalyses();
  }, [sampleType, searchTerm, statusFilter, dateFilter, analyses]);

  return (
    <div className="admin-panel">
      <h1>Central de Analises e Inteligência</h1>


      <button className="open-modal-btn" onClick={() => setShowModal(true)}>
        Ver Análises
      </button>

      {showModal && !selectedAnalysis && (
        <div className="modal-overlay" onClick={handleCloseModal}>
          <div className="modal-content">
            <h2>Análises</h2>

            {/* Barra de Filtros dentro do modal */}
            <div className="filter-bar">
              <div className="filter-group-pesquisar">
                <label htmlFor="searchTerm">Pesquisar:</label>
                <input
                  id="searchTerm"
                  type="text"
                  value={searchTerm}
                  placeholder="Buscar por nome ou palavra-chave..."
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              <div className='filter-group-part2'>
                <div className="filter-group">
                  <label htmlFor="sampleType">Tipo de Amostra:</label>
                  <select 
                    id="sampleType" 
                    value={sampleType} 
                    onChange={(e) => setSampleType(e.target.value)}
                  >
                    <option value="">Todos</option>
                    <option value="agua">Água</option>
                    <option value="solo">Solo</option>
                    <option value="ar">Ar</option>
                    <option value="alimento">Alimento</option>
                  </select>
                </div>

                <div className="filter-group">
                  <label htmlFor="statusFilter">Status:</label>
                  <select 
                    id="statusFilter" 
                    value={statusFilter} 
                    onChange={(e) => setStatusFilter(e.target.value)}
                  >
                    <option value="">Todos</option>
                    <option value="em espera">Em Espera</option>
                    <option value="em andamento">Em Andamento</option>
                    <option value="concluída">Concluída</option>
                  </select>
                </div>

                <div className="filter-group">
                  <label htmlFor="dateFilter">Filtrar por Data:</label>
                  <input
                    id="dateFilter"
                    type="date"
                    value={dateFilter}
                    onChange={(e) => setDateFilter(e.target.value)}
                  />
                </div>
                <button className="clear-filters-btn" onClick={handleClearFilters}>Limpar Filtros</button>

              </div>
            </div>


            <div className="analyses-list">
              {filteredAnalyses.length > 0 ? (
                filteredAnalyses.map((analise, index) => (
                  <div key={index} className="analise-card" onClick={() => handleOpenAnalysis(analise)}>
                    <h3>{analise.title}</h3>
                    <p><strong>Tipo de Amostra:</strong> {analise.analysisData[0].sampleType}</p>
                    <p><strong>Responsável:</strong> {analise.analysisData[0].responsibleName}</p>
                    <p><strong>Data de Coleta:</strong> {new Date(analise.analysisData[0].collectionDate).toLocaleDateString()}</p>
                    {analise.analysisData[0].samplePhoto && (
                      <div className="image-container">
                        <img 
                          src={`${process.env.REACT_APP_API_BASE_URL}/uploads/${analise.analysisData[0].samplePhoto}`}
                          alt="Foto da Amostra"
                        />
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <p>Não há análises disponíveis.</p>
              )}
            </div>
            <button className="close-modal-btn" onClick={handleCloseModal}>Fechar</button>
          </div>
        </div>
      )}

      {selectedAnalysis && (
        <div className="modal-overlay" onClick={handleCloseModal}>
          <div className="modal-content">
            <AnaliseFullByAdmin
              selectedAnalysis={selectedAnalysis}
              handleBackToList={() => setSelectedAnalysis(null)} // Fecha o modal de análise full e volta à lista
            />
          </div>
        </div>
      )}

      <PromptSection />
    </div>
  );
};

export default AdminPanel;

