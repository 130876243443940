import React, { useState } from 'react';
import './IAPanel.css';
import PromptSection from './PromptSection'; // Importa o novo componente de prompts

const IAPanel = ({ isOpen, handleClose }) => {
  const [iaResponse, setIaResponse] = useState('');
  const [loading, setLoading] = useState(false);
  const [prompt, setPrompt] = useState('');

  if (!isOpen) return null;

  const handleSendPrompt = async () => {
    if (!prompt.trim()) return;
    setLoading(true);

    setTimeout(() => {
      setIaResponse(`Resposta da IA para o prompt: "${prompt}"`);
      setLoading(false);
    }, 2000);

    setPrompt('');
  };

  return (
    <div className="ia-panel-overlay" onClick={handleClose}>
      <div className="ia-panel-modal" onClick={(e) => e.stopPropagation()}>
        <h2>Painel de Inteligência Artificial</h2>
        <button className="close-button" onClick={handleClose}>X</button>

        <div className="ia-prompt-section">
          <textarea
            placeholder="Digite sua solicitação para a IA..."
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            rows="4"
            cols="50"
          />
          <button onClick={handleSendPrompt} disabled={loading}>
            {loading ? 'Aguarde...' : 'Enviar Prompt'}
          </button>
        </div>

        <div className="ia-response-section">
          {loading ? <p>Processando solicitação...</p> : <p>{iaResponse || 'Aguardando entrada do usuário...'}</p>}
        </div>

        <PromptSection />
      </div>
    </div>
  );
};

export default IAPanel;
