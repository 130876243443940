// standardPrompt/StandardPrompt.js
import React from 'react';
import './StandardPrompt.css';

const StandardPrompt = ({ image, title, text }) => {
  return (
    <div className="prompt-card">
      <img src={image} alt={title} className="prompt-image" />
      <h3 className="prompt-title">{title}</h3>
      <p className="prompt-text">{text}</p>
    </div>
  );
};

export default StandardPrompt;
