import React, { useState } from 'react';
import axios from 'axios';
import './Prompt.css';

const Prompt = ({ isOpen, onClose, onSave }) => {
  const [avatar, setAvatar] = useState(null);
  const [promptName, setPromptName] = useState('');
  const [promptCommand, setPromptCommand] = useState('');
  const [loading, setLoading] = useState(false);

  if (!isOpen) return null;

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setAvatar(reader.result); // Salva a imagem como base64
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSavePrompt = async () => {
    if (!promptName || !promptCommand) {
      alert('Por favor, preencha todos os campos.');
      return;
    }

    const newPrompt = { avatar, promptName, promptCommand };
    setLoading(true);

    try {
      // Envia o novo prompt ao backend
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/prompts`, newPrompt);
      onSave(response.data.prompt); // Salva o novo prompt na lista
      onClose(); // Fecha o modal
    } catch (error) {
      console.error('Erro ao salvar prompt:', error);
      alert('Erro ao salvar prompt.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="create-prompt-overlay" onClick={onClose}>
      <div className="create-prompt-modal" onClick={(e) => e.stopPropagation()}>
        <h2>Criar Novo Prompt</h2>

        {avatar && (
          <div className="avatar-preview">
            <img src={avatar} alt="Avatar Preview" />
          </div>
        )}

        <input type="file" accept="image/*" onChange={handleImageUpload} />
        <input
          type="text"
          placeholder="Nome do Prompt"
          value={promptName}
          onChange={(e) => setPromptName(e.target.value)}
        />
        <textarea
          placeholder="Comando do Prompt"
          value={promptCommand}
          onChange={(e) => setPromptCommand(e.target.value)}
          rows="4"
        />

        <div className="modal-buttons">
          <button onClick={handleSavePrompt} disabled={loading}>
            {loading ? 'Salvando...' : 'Salvar'}
          </button>
          <button onClick={onClose}>Fechar</button>
        </div>
      </div>
    </div>
  );
};

export default Prompt;
